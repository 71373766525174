/* Questionnaire Common ***************************************************/

.questionnaire-editor {

  .evidence{
    clear: both;
  }

  table{
    width: 100%;
  }

  .justification-section{

    textarea{
      border-radius: 0px;
      padding: 6px;
      min-height: 32px;
    }
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  .questionnaire-filter-menu {
    padding: 0;
  }

  .question {
    border-bottom: 1px solid #eee;
    padding: 1em 0.5em;
  }

  .question:nth-child(odd) {
    //  background: #f4f4f4;
  }

  .question:hover {
  }

  .question-read-only {

  }

  .question-subsection {
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    color: @color3;
    text-transform: uppercase;
    padding: 1em 0 0.5em 0;
    margin-top: 1em;
  }

  .question-editor {
    position: relative;
    background: @body-bg;
    //  background: @mutedColorLight;
    //  margin-bottom: 0.5em;
    border: 1px solid @mutedColor;
    padding: 0;
    border-color: @mutedColor;

    transition:
    border-color 0.5s ease-in-out,
    background-color 0.5s ease-in-out;

    label {
      font-weight: normal;
    }

    &:hover {
    }

    .document-type{
      background: lighten(@grayLighter, 1%);
      margin: 0px!important;
      margin: 12px 0px 0px !important;
      .form-group{
        margin-bottom: 5px!important;

        .checkbox{
          margin: 0px!important;
        }
      }
    }
  }

  .question-label {
    margin-bottom: 0.5em;

    label {
      display: inline; // make it wrap around other divs
    }
  }

  .question-editor-content {
    padding: 1em;

    .form-single-choice-buttons-editor{
        padding-bottom: 0px;
    }
  }

  .question.inactive {
    opacity: 0.2;
  }

  .question-toolbar {
    color: #aaa;
    padding: 0 1em 0.5em 1em;

    .icon {
      font-size: 1.5em;
    }

    a {
      color: @text-color;
    }
  }

  .question-help-text {
    margin: 1em;
    padding: 1em;
    border-left: 3px solid @mutedColor;
    p {
//    margin: 0;
    }

  }

  .question-label-block {
    float: right;

    .question-level-label {
      padding: 0.5em;
      margin: 0 0 1em 0;
      display: inline-block;
      background: @mutedColor;
    }

    .agriform-label {
      padding: 0.5em;
      margin: 0 0 1em 1em;
      display: inline-block;
    }
  }

  .editor {

  }

  .editor-radio {
    .btn {
      margin-right: 0.5em;
    }
  }

  ul.answer-set {
    &.inline-options {
      li {
        display: inline;
      }
      label {
//      display: inline;
      }
      input {
        width: auto;
//      display: inline;
      }
    }
  }

  .justification-section {
    margin: 1em 0 0 0;
  }

  .help-bubble {
    font-size: 1.5em;
    display: inline-block;
    vertical-align: middle;
  }

  .assessment-logo {
    padding: 0.5em 0;
    text-align: center;

    img {
//      width: 175px;
      height: 50px;
    }
  }

  .questionnaire-toolbar.affix {
    position: fixed;
    top: 0;
    //  width: 970px; // fixed width hack, otherwise element loses width
    width: 220px; // fixed width hack, otherwise element loses width
    z-index: 100;
    border-bottom: 1px solid @mutedColorDark;

    .pull-right {
      display: none;
    }
  }

  .question-info {
    font-size: 0.75em;
    font-weight: bold;
    color: @gray;
  }

//
// Questionnaire outline
//

  .questionnaire-outline {
    font-size: 0.9em;
    margin-bottom: 20px;
  }

  .questionnaire-outline-nav {
    a {
      padding: 0.5em !important;
    }

  }

  .questionnaire-element {
    margin: 0 0 1.5em 0;
  }

  .attached-documents {
    list-style: none;
    margin-top: 0;

    li {
      margin: 0;
      padding: 0.5em 0;
    }
  }

  .assessment-attachment {
    opacity: 0.8;
  }

  button.btn-link{
    padding: 0px;
  }

  table{
    width: 100%;
  }

}


.questionnaire-editor-app {
  .question-text{
    font-size: 14px;
  }

  td.left-col{
    // width: 1%;
    // white-space: nowrap;
    vertical-align: top;
  }

  td.right-col{
    padding-left:15px;
    height: auto;
    vertical-align: top;
  }

}

.edit-agriform-attachment{
  .questionnaire-editor {
    td.left-col{
      width: 100%;
    }
    td.right-col{
      padding: 0px;
    }
  }
}
