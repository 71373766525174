//
// Elements
//
* {
  margin: 0;
}

html, body {
  position: relative;
  height: 100%;
}

body {
  &.gray {
    background:  url("../img/noisy-2.png") @grayLighter;
  }
}

form {
  margin: 0;
}

a {
}

hr {
  margin: 1.5em 0;
}

hr.major {
  border-top-width: 3px;
}

h1,h2,h3,h4 {
  font-family: @font-family-headings;
  padding: 0;
}

h1 small, h2 small, h3 small, h4 small {
  font-weight: normal;
  font-size: 0.9em;
  color: @grayLight;
}

h1 {
//  font-size: 2 * @font-size-base;
//  font-weight: bold;
  margin-bottom: 0.5em;
  color: @color3;
  letter-spacing: -1px;
}

h1:first-child, h2:first-child {
  margin-top: 0;
}

h2 {
//  font-size: 1.75 * @font-size-base;
    letter-spacing: -1px;
    color: @color1Dark;
}

h3 {
//  font-size: 1.5 * @font-size-base;
  color: @color1Dark;
}

h4 {
//  font-size: 1.25 * @font-size-base;
  margin-bottom: 0;
}

.container {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    margin: 0 1em;
  }
}

.btn-link {
  /* Force link buttons to wrap, otherwise they break out of container */
  word-break: break-all;
  white-space: normal;
}

.centered {
  text-align: center;
}

.dl-inline dt, .dl-inline dd {
  display: inline;
}


.body-dark {
  background: #404547;
}

.body-dark #logo-slice {
  background: #fff;
}

.body-muted {
    background: @mutedColorLight;
}

.container {
  padding: 1em;
}

#topLinks {
/*display: none;*/
}

.round {
  border-radius: 5px;
}

.padded-content-small {
  padding: 1em;
}

.padded-content {
  padding: 1em;
}

.bordered {
  border: 1px solid #eee;
}



/*** Help box ***/
.help-box {
  text-shadow: #fff 1px 1px;
  -webkit-text-shadow: #fff 10px 0;
  padding-left: 1em;
  color: #555;
  border-left: 1px solid #E0E5E7;
}

.help-box h3 {
  font-size: 1em;
  margin: 1em 0 0 0;
  padding: 0;
  font-weight: bold;
}

.help-box h3:first-child {
  margin: 0;
}




/*** Layout: ***/

.container {
  padding: 0;
}


/*** Logo Slice **************************************************************/
#logo-slice {
  background: #fff;
  position: relative;
  padding: 1em 0;
  border-bottom: 1px solid @grayLighter;
}

#logo-slice.dark {
  background: url("../img/noisy-2.png") @color3;
  color: white;
}

#logo-slice .toolbar {
  padding: 0.5em 0;
  float: right;
}

#logo-slice.dark a {
//  color: white;
}

#logo-slice .current-organization {
  display: inline-block;
  margin-left: 0.5em;
  color: @white;
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: -1px;
  text-shadow: @color1Dark 0 1px 1px inset;
}

#logo-slice .corner-menu {
  float: right;
  margin: 0.5em 0;
}

.navbar-inner {
  background: @brand-primary;
}

.agriplace-logo img {
  height: 40px;
}

.p4e-logo {
  display: inline-block;
  padding-right: 0.5em;
  font-family: @font-family-headings;
  font-size: 1.3em;
  font-weight: bold;
  text-shadow: #fff 0 1px;
  color: darken(@color1, 10%);
  text-decoration: none;
  border: 1px solid #333;
  border-radius: 3px;
  background: #333;
//  box-shadow: 0 3px 3px rgba(0,0,0,0.3);
}

.p4e-logo a {
  text-decoration: none;
}

.p4e-logo.big {
  font-size: 28px;
}

.p4e-logo.dark, .p4e-logo.dark a {
  text-shadow: #000 2px 0;
  color: white;
}

.p4e-logo img {
  width: 1.7em;
}

#logo-slice .nav {
  margin: 0;
  padding: 0;
}

.logo-slice a {
  color: #000;
}


/*.header .nav-tabs {
	border: 0;
	xfont-size: 1.2em;
	font-family: Trebuchet, sans-serif;
	xletter-spacing: -1px;
	xfont-weight: bold;
}

.header .nav-tabs li {
	margin: 0 5px 0 0;
	border: 0;
}

.header .nav-tabs a:hover {
	border-color: #404547;
	border-bottom: 0;
	background: #404547;
}

.header .nav-tabs .active a {
	color: #333;
	background: #f0f5f7;
}

.header .logo {
	color: #555;
	letter-spacing: -1px;
	display: inline-block;
	padding: 0.5em 0 0 0;
	font-family: Open-Sans;
	font-weight: 300;
	font-size: 24px;
	border-radius: 0 0 3px 3px;
}*/

/*** Footer slice ************************************************************/

//
// Push footer to bottom of page
// http://ryanfait.com/resources/footer-stick-to-bottom-of-page/
.document-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -120px;
}

.footer-slice, .push {
  height: 100px;
}

.footer-slice {
  min-width: 1080px;
  width: 100%;
  height: 100px;
  background:  url("../img/noisy-2.png") darken(@grayLighter, 10%);
  color: @gray;
  font-size: 0.9em;
  margin: 0;
  padding: 1em 0;
}

/*** Navbar Slice ************************************************************/

#navbar-slice {
  background: url("../img/noisy-2.png") @color1;
  padding: 0;
  margin-bottom: 0;
  font-family: @font-family-headings;
  font-weight: bold;
  text-transform: uppercase;

  a .caret {
    border-top-color: #eee;
    border-bottom-color: #eee;
  }

  .icon {
    font-size: 0.9em;
  }

  .caret a {
    color: red;
  }

  .typcn {
    font-size: 20px;
    padding: 0;
  }

  .dropdown-menu {
    font-family: @font-family-base;
    text-transform: none;
  }

  .navbar-brand {
    padding: 0;
    margin: 0 1em 0 0;

    img {
      height: @navbar-height;
    }
  }

  .nav .active a {
    background:  url("../img/noisy-2.png") @color1Dark;
    //    box-shadow: rgba(0,0,0,0.5) 0 0 10px inset;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }


  .nav-content-right .nav {
    margin-right: 1em;
  }

//
//  .xicon-label {
//    padding: 0;
//    display: inline-block;
//    vertical-align: middle;
//
//    .icon {
//      display: inline-block;
//      vertical-align: middle;
//      padding: 0;
//    }
//
//    .content {
//      display: inline-block;
//      vertical-align: middle;
////      padding: 0.5em 0;
//    }
//  }
//
//



}

#navbar-slice .navbar-inverse .navbar-inner {
//  background: none;
/*	border: 1px solid #101517 !important;*/
}

/*** Breadcrumbs Slice ************************************************************/

.breadcrumbs-slice {
  margin-bottom: @font-size-base;
  border-bottom: 1px solid @mutedColor;
  padding: 0.5em;



}


/*** Breadcrumbs Slice ************************************************************/


.toolbar-nav {
  padding: 0.5em 0;
}


/*** Organization navbar slice ***/
#organization-navbar-slice {
  background: @color2;
}

.navbar-inverse .nav > li > a {
  color: #eee;
}

.navbar-inner {
  padding-right: 0 !important;
  background: #f0f5f7;
  border: 1px solid #d0d5d7;
  border-bottom: 2px solid #d0d5d7;
  background: none;
  border-width: 0;
  font-size: 1em;
}

/*
.navbar-inner {
}

.navbar .nav > .active a {
	background: #e0e5e7;
	border-radius: 5px;
}*/

.brand {
/*font-family: Open-Sans, Helvetica Neue, Helvetica, Arial, sans-serif;*/
  font-weight: 200 !important;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-size: 14px !important;
  color: #777 !important;
}


.header .nav {
  margin-bottom: 0;
}

.header .top-photo {
  width: 100%;
  height: 100px;

}

/*** Organization Slice ******************************************************/
.organization-slice {
  background: url("../img/noisy-2.png") lighten(@color1, 50%);
  position: relative;
//  top: -1px;
  height: 20px;
  padding: 15px;

  font-size: 1.25em;
  font-weight: bold;

  .organization-logo {
    height: 32px;
  }
}

.organization-slice span[class*="icon-"] {
  font-size: @font-size-base;
  font-weight: normal;
}

.tiny-link {
  font-size: 0.6em;
  font-weight: normal;
}

/*** Content slice ***********************************************************/
#content-slice {
  padding: 1em 0 3em 0;
  background: #ffffff;
  margin-bottom: 0;
  min-height: 500px;
}

.page {
}

.page-content-title {
  margin-bottom: 1em;
}

.light-box {
}

.content {
  padding: 0 1em;
}

.children-box {
/*	background: #25a4e8;*/
  background: #f0f7f5;
  padding: 0 1em 1em 1em;
  color: #fff;
}

.children-box a {
  color: #fff;
}

.children-box a.btn {
  color: #333;
}

.children-box ul {
  margin: 0;
  padding: 0;
}

.children-box h3 {
/*	color: #eee;*/
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.main-content {
/*background: #ffffff;*/
/*padding: 1em;*/
/*border-radius: 5px;*/
/*border: 1px solid #ddd;*/
/*box-shadow: 0 1px 4px rgba(0,0,0,.07);			*/
}

.page {
}




.sidebar {
  border: 1px solid #ddd;
  font: 0.75em;
}

.sidebar-highlighted {
  border: 1px solid #ddd;
  background: #f4f4f4;
}


/*** Section ***/
.section {
  background: #fff;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.section-content {
  padding: 10px;
}

/*** Tables ***/

// TODO Move to bootstrap or custom style

//.table {
//  border-bottom: 1px solid #ddd;
//  margin-bottom: 5px;
//}
//
//.table td {
//  border-color: #f4f4f4;
//}
//
//.table select {
//  margin: 0;
//}
//
//.table tr.active, .table tr.active:hover, .table tr.active td:hover {
//  background: lightyellow;
//}

.align-last-td-right thead th:last-child, .align-last-td-right tbody td:last-child {
  text-align: right;
}

.align-last-td-right thead th:only-child, .align-last-td-right tbody td:only-child {
  text-align: left;
}

.display-value {
  font-weight: bold;
}

.detail-table {

}

.detail-table .details {
  font-size: 0.8em;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
}


/*** Forms ***/
form ul {
  list-style: none;
  margin-left: 0;
  padding: 0.5em 1em;
}

.form-section {
  border: 1px solid #ddd;
  border-width: 1px 0 0 0;
  padding: 1em 0;
}

.form-section closed {
  border-width: 1px 0 1px 0;
}

.errorlist {
  list-style: none;
  margin: 0;
}

.errorlist li {
  display: inline-block;
  padding: 3px;
  background: #fff0ef;
  color: #B94A48;
}

.clearfix {
  clear: both;
}

.breadcrumb {
  margin: 0;
}


/*** Sub-nav ***/
.subnav {

}


/*** Toolbar ***/
.toolbar1 {
/*background: #d0d5d7;*/
  background: #25a4e8;
/*background: -webkit-linear-gradient(center, #e0e5e7, red);*/
/*background: -webkit-linear-gradient(top, #119ce7, #008bd6);*/
  padding: 10px;
  border: 1px solid #0382c6;
/* 3d wrap around */
  position: relative;
  margin: 1em -10px;
  width: 100%;
  border-radius: 3px;
/* Bump buttons to the right */
  text-indent: 10px;
}


.toolbar3 {
  display: block;
  padding: 0 0 1em 0;
}


/*** Affix ***/

/* Affixed element in normal position */
.affix-top {
}

/* Affixed element when floating */
.affix {
  position: fixed;
  top: 0;
}



/*** Flash messages ***/
.messages {
  list-style: none;
  margin: 0;
  padding: 0;
}


/*** PAGES ***/

/*** organization_access ***/
.user-input-row {
  background: #f0f5f7;
}

/*** Buttons ***/
.btn2 {
  width: auto;
  background: #2ba6cb;
  border: 1px solid #1e728c;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 10px 20px 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
/* Hovers */
/* Sizes */
/* Colors */
/* Radii */
/* Layout */
/* Disabled ---------- */
}
.btn2:hover { color: white; background-color: #2284a1; }
.btn2:active { -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset; -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset; box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset; }
.btn2:focus { -webkit-box-shadow: 0 0 4px #2ba6cb, 0 1px 0 rgba(255, 255, 255, 0.5) inset; -moz-box-shadow: 0 0 4px #2ba6cb, 0 1px 0 rgba(255, 255, 255, 0.5) inset; box-shadow: 0 0 4px #2ba6cb, 0 1px 0 rgba(255, 255, 255, 0.5) inset; color: white; }
.btn2.large { font-size: 17px; padding: 15px 30px 16px; }
.btn2.medium { font-size: 14px; }
.btn2.small { font-size: 11px; padding: 7px 14px 8px; }
.btn2.tiny { font-size: 10px; padding: 5px 10px 6px; }
.btn2.expand { width: 100%; text-align: center; }

.bnt2.primary { background-color: #2ba6cb; border: 1px solid #1e728c; }
.btn2.primary:hover { background-color: #2284a1; }
.btn2.primary:focus { -webkit-box-shadow: 0 0 4px #2ba6cb, 0 1px 0 rgba(255, 255, 255, 0.5) inset; -moz-box-shadow: 0 0 4px #2ba6cb, 0 1px 0 rgba(255, 255, 255, 0.5) inset; box-shadow: 0 0 4px #2ba6cb, 0 1px 0 rgba(255, 255, 255, 0.5) inset; }

.btn2.radius { -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px; border-radius: 3px; }

.frame {
  overflow: auto;
  border: 1px inset;
  border-radius: 5px;
  padding: 1em;
  max-height: 10em;
}



/*** UL Tree ***/
ul.tree {
  margin: 0;
  padding: 0;
}

ul.tree li {
  list-style: none;
  padding: 0.5em 0;
}

ul.tree li {
}

ul.tree .children {

}

.treeui ul li {
  padding: 0.5em 0;
  xmargin: 0;
}


/*** List ***/

.list {

}

.list-item {
  border-top: 1px solid #d0d5d7;
  background: #f0f5f7;
  padding: 1em;
  margin-bottom: 0.5em;
}



/*** Nav ***/
.nav-list {
  padding: 0;
}

.nav-list a {
  color: #333;
}

/*** Help bubble ***/
.help-bubble {
  margin-left: 0.1em;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: normal;
}

.help-bubble:hover {
  text-decoration: none;
}

/* Organization layout ***************************************************/

.organization-tree {
  border-right: 1px solid #ddd;
}


/*** Icon box ***/
.list-icon {
  padding: 1em;
/*	border-radius: 3px;*/
}
.list-icon.active {
  background: #1493D7;
  color: #fff;
  font-weight: bold;
/*	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5) inset;*/
}
.list-icon:hover {
  background: #f0f5f7;

}

/*** Icons area ***/
ul.icons-box {
  padding: 1em 0;
  list-style: none;
  margin: 0;
  padding: 1em 0;
}

ul.icons-box li {
  display: inline-block;
}


ul.icons-box li .i {
}

.navlist > li > a {
  margin: 0 !important;
}


#movement-slice {
  background: url("../img/noisy-2.png") white;
  box-shadow: rgba(0,0,0,0.5) 0 0 10px inset;
}

.small {
  margin: 0.5em 0;
  font-size: 0.8em;
}


/* organization business card */

.businesscard {
    hr {
        margin: 15px 0;
    }
    p {
        margin-bottom: 10px;
    }
}




//
// Bootstrap overrides
//

//textarea:focus,
//input[type="text"]:focus,
//input[type="password"]:focus,
//input[type="datetime"]:focus,
//input[type="datetime-local"]:focus,
//input[type="date"]:focus,
//input[type="month"]:focus,
//input[type="time"]:focus,
//input[type="week"]:focus,
//input[type="number"]:focus,
//input[type="email"]:focus,
//input[type="url"]:focus,
//input[type="search"]:focus,
//input[type="tel"]:focus,
//input[type="color"]:focus,
//.uneditable-input:focus {
//  border-color: @color1;
//  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px @color1;
//  outline: 0 none;
//}

.checkbox label {
  vertical-align: middle;
}

.typcn {
  font-size: 1.2em;
}


//.list-group {
//  box-shadow: none;
//  border: 0;
//}
//
//.list-group-item {
//  border: 0;
//  padding: 0.5em;
//
//  .link-icon {
//    display: none;
//  }
//
//  &.active {
//    border-radius: 3px;
//
//    .link-icon {
//      display: inline;
//    }
//  }
//}

.feedback-button {
//  display: block;
//  position: fixed;
//  float: right;
//  right: 0;
//  top: 35px;
//  margin: 0;
  padding: 0.5em 1em;
//
//  transform-origin: 100% 100%;
//  -webkit-transform-origin: 100% 100%;
//  transform: rotate(270deg);
//  -webkit-transform: rotate(270deg);
//
  background: @color1;
//
  color: @text-color;
//
//  &:hover {
//    color: @text-color;
//    background: darken(@color3Light, 10%);
//  }
//
  .fa {
    font-size: 1.5em;
    margin-right: 0.5em;
  }
}

.panel-condensed {
  .panel-body {
    padding: 0.5em;

  }
}

.loading-feedback {
  text-align: center;
  color: @color1;
  padding: 1em;
}

.choose-products {
  margin: 1em 0;
}

.header-label {
  font-size: 0.8em;
  font-weight: bold;
  font-color: @color3;
}

.list-row {
  padding-bottom: 1em;
  border-bottom: 1px solid @mutedColor;
  margin-bottom: 1em;
}

.list-row-details {
    margin-top: 1em;
}

.evidence-table {
  td {
    padding: 0.5em 0;
    vertical-align: top;
  }
}

.assessment-logo {
  &.small {
    height: 32px;
  }
}

.assessment-logo-placeholder {
  display: inline-block;
  vertical-align: middle;
  padding: 1em;
  background: @color1Light;
  color: white;

  &.small {
    height: 32px;
  }
}

&.answered {
        background: #dee3d3 !important;
//  background: #eff4e4 !important;
}

.evidence-table .list-row td {
  padding: 0.5em;
}

.assessment-type-item {
}

div.dataTables_paginate {
  float:none !important;
}

.toast-top-right {
  top: 50px;
}

.evidence-list-app {
  .questionnaire-toolbar {
    .help-icon {
      font-size: 2em;
    }
  }
}

.questionnaire-editor-app {
  .questionnaire-toolbar {
    .help-icon {
      font-size: 2em;
    }
  }
}

// Animations


.highlight {
  transition: all 2s ease-out;
  -webkit-transition: all 2s 2s ease-out;
  -moz-transition: all 2s ease-out;
}

.highlight.ng-enter {
  background: yellow;
}

.highlight.ng-enter.ng-enter-active {
  background: inherit;
}

.nav-wizard-wrapper {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  margin: 0 0 1em 0;
  .nav-wizard-slot {
    padding-left: 0px;
    .nav-wizard {
      border: none;
      margin: 0px;
    }
  }
  .nav-controls-slot {
    .row {
        line-height: 46px;
    }
  }

}
.assessment-results {
  .lead {
    p {
      display: inline-block;
    }
  }
  tr:first-child,
  tr:last-child {
    td {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.assessment-list {
  .toolbar {
    .help-icon {
      font-size: 2em;
    }
  }
}
.nav-description {
  color: darkgray;
}

.nav-tabs li.active a {
    border-top: 2px solid @color1;

    &:hover {
        border-top: 2px solid @color1;
    }
}

.attachments-box-section {
    padding: 1em;
    border-left: 1px solid @mutedColor;
}

.organization-logo {
    font-size: 72px;
    color: @mutedColor;
}

.test {
  background: red;
}

.paper {
  background: white;
  padding: 2em;
}

.lead{
  margin-top: 22px;
  margin-bottom: 10px;
  font-size: 13.5px;
}

.evidence-intro {
  margin-top: 2px;
  margin-bottom: 18px;
  font-size: 13.5px;
}

.leaflet-container {
  width: 100%;
  height: 400px;
  // Override pathes for leaflet-fullscreen plugin
  .fullscreen-icon { background-image: url('../img/icon-fullscreen.png') !important ; }
  .leaflet-retina .fullscreen-icon { background-image: url('../img/icon-fullscreen-2x.png') !important ; }
}
.RvoImport {
  .has-feedback .form-control-feedback {
    top: 0
  }
}

.home-profile-image > .img-responsive {
  max-height: 170px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media print {
  a:after { content:''; }
  a[href]:after { content: none !important; }
}

@media print {
  .container {
    width: 100%;
  }
}
