//
// Assessments
//

.assessment-thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
}

.new-assessment {
  img {
    width: 210px;
    height: 210px;
  }

  a:hover {
    text-decoration: none;
  }

  height: 350px;
}

/* organization public profile */

.organization-public-profile {
  .organization-logo {
    //    height: 50px;
    //    max-width: 100px;
  }
  .section-map {
    iframe {
      border: 0 !important;
      width: 300px;
      height: 100%;
      min-height: 300px;
    }
    img {
      width: 778px;
      height: 300px;
    }
  }
}

//.profile-section {
//  .box(lighten(@color3, 50%));
//  .profile-section-header {
//    .box-header;
//    background: inherit;
//    padding: 0 1em;
//  }
//  .profile-section-content {
//    .box-content;
//  }
//}

//
// Profile
//

.public-profile-header {
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-bottom: 1em;
}

.public-profile-header-img {
  width: 100%;
}

.profile-page {
  background: white;
  border: 1px solid @mutedColorDark;
}

.profile-section {
}

.profile-section-header {
  background: inherit;
  padding: 0 1em;
}

.profile-section-content {
  padding: 1em;
}

.section-map {
  border: 0;
  padding: 0;
  border-radius: 3px;
  overflow: hidden;
}

ul.photos {
  list-style: none;
  margin: 0;
  padding: 0;
}

.photo {
  display: inline-block;
  margin-right: 1.5em;
}

.photo img {
  width: 80px;
}

// Certificates

.certificates {
  padding: 0;
}

.assessment-create-logo {
  max-width: 300px;
  max-height: 300px;
}

//
//
//

// Allow list view to expand
// Not limited to box
.unlimited-list-view {

  .list-view {
    overflow: inherit;

    .select-box {
      height: auto;
      overflow: inherit;
    }

  }
}

.archive-app {

  margin-left: 10px;
  .toolbar {
    background: @color3Lighter;
  }


  .select-box {
    height: 100%;
  }
}







// the home page

@keyframes animateHeader{
    0%,
    100% {
      left: 0px;
    }
    23.5% {
      left: 3px;
    }
}

.home-box {
    -webkit-box-shadow: 5px 5px 25px 5px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    5px 5px 25px 5px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         5px 5px 25px 5px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    min-height: 290px;
}

.home-box > .panel-heading {
    background      : @color1;
    height          : 55px;
    transition      : background-color .5s;
}

.home-box > .panel-heading > a > h1.panel-title {
    font-size   : 2.5em;
    color       : @white;
}

.home-box > .panel-heading > a > span {
    position : relative;
    color       : @white;
    margin-top  : -28px;
}

.home-box > .panel-heading:hover > a > span,
.home-box > .panel-heading:hover > a {
  color               : #eee;
  animation           : animateHeader 0.5s;
  animation-timing-function : ease-out;
  animation-fill-mode       : forwards;
}

.home-box > .panel-body > .list-group > .list-group-item {
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.home-box > .panel-body > .list-group > .list-group-item:hover{
  background-color: #eee;
}

.home-box > .panel-body > .list-group > .list-group-item > a > .row h4 {
  text-align: left;
  margin: 0 0 0.5em 0;
  color: @gray;
}

.home-box > .panel-body > .list-group > .list-group-item > a > .row span {
  position: relative;
  top: 4px;
}

.home-profile-organization{
  margin-top    : -0.8em;
  margin-bottom : 0.8em;
}

.home-profile-crops span,
.home-profile-certificates span,
.home-certification-item{
  font-size : 1.1em;
}

.home-profile-crops i,
.home-profile-certificates i{
  margin-top: -2px;
  transition: all .2s ease-in-out;
}

.home-profile-crops {
  margin-bottom: 0.6em;
}

.home-profile-crops:hover i,
.home-profile-certificates:hover i{
  transform: scale( 1.1 );
}

// image replacement help text
.home-profile-image-replacement h5{
  margin-top  : 5px;
  visibility  : hidden;
  opacity     : 0;
  transition  : opacity 0.3s;
}

.home-profile-image-replacement:hover h5{
  text-align  : center;
  color       : @gray;
  visibility  : visible;
  opacity     : 1;
}

.gray-text{
    color: @gray;
}

#home-organization-title{
  font-size   : 4.7em;
  font-weight : 500;
  text-align  : center;
  color       : @color3;
}

#home-boxes {
  width   : 100%;
  height  : 100%;
  // overflow: hidden;
}

// profile box

#home-profile-box{
    margin-right: 45px;
}

.home-box a:hover{
    text-decoration: none;
}

#home-box-header {
    background-color: @color1;
    height          : 55px;
    transition      : background-color .5s;
}

#home-box-header:hover{
}

// the box titles
#home-box-header h1{
    color       : white;
    margin-top  : 2px;
}

// the arrow/chevron
#home-box-header span {
    position : relative;
    color       : white;
    margin-top  : -45px;
}


#home-box-header a {
    position : relative;
}

#home-box-header:hover span,
#home-box-header:hover a {
  color               : #eee;
  animation           : animateHeader 0.5s;
  animation-timing-function : ease-out;
  animation-fill-mode       : forwards;
}



#home-profile-box-content{
    width       : 500px;
    margin-top  : 20px;
}

// limit image size to 150px squares
#home-profile-image,
#home-profile-image-replacement{
    width       : 150px;
    height      : 150px;
    margin-left : 25px;
    margin-top  : 14px;
    float       : left;
}

#home-profile-image img,
#home-profile-image-replacement img{
    width   : inherit;
    height  : inherit;
}


// image replacement help text
#home-profile-image-replacement h5{
  margin-top  : -5px;
  visibility  : hidden;
  opacity     : 0;
  transition  : opacity 0.3s;
}

#home-profile-image-replacement:hover h5{
  text-align  : center;
  color       : @gray;
  visibility  : visible;
  opacity     : 1;
}

#home-profile-info{
    height        : 190px;
    width         : 298px;
    margin-left   : 191px;
    padding-left  : 15px;
    overflow      : hidden;
}


#home-profile-info div{
  height      : 20px;
}

#home-profile-organization{
  height        : 125px !important;
  margin-top    : -11px;
  margin-bottom : 6px;
  overflow      : auto;
  overflow-x    : hidden;
}

#home-profile-organization h3{
  color         : @color-f;
  margin-bottom : -2px;
}

#home-profile-organization h5{
  margin-bottom        : -2px;
}

#home-profile-crops{
  margin-bottom : 11px;
}


#home-profile-crops span,
#home-profile-certificates span,
.home-certification-item{
  font-size : 1.2em;
}


#home-profile-crops i,
#home-profile-certificates i{
  margin-left: 8px;

  margin-top: -2px;
  transition: all .2s ease-in-out;
}

#home-profile-crops:hover i,
#home-profile-certificates:hover i{
  transform: scale( 1.1 );
}




// certification box

#home-certification-box{
    margin-right    : 50px;
    float           : left;
}


#home-certification-content{
    width       : 500px;
    margin-top  : 21px;
}

#home-certification-content span{
  position: relative;
  top: 12px;
}

.home-certification-item{
    width               : 440px;
    height              : 43px;
    margin-left         : 30px;
    margin-bottom       : 8px;
    padding-left        : 15px;
    float               : left;
    border              : solid 1px #ddd;
    background-color    : #fff;
    -webkit-transition  : background-color 0.2s, color 0.2s;
    transition          : background-color 0.2s, color 0.2s;
}

.home-certification-item:hover{
    background-color: #eee;
}

// the fa icons
.home-certification-item i{
    margin-top    : 5px;
    margin-left   : -15px;
}



#home-certification-last-opened{
    margin-top: 8px;
    height: 75px;
}

#home-last-opened{
  text-align: left;
  margin-left: -16px;
  margin-bottom: 5px;
}

.certification-box {
  .certificate-name-label {
    color: @color1;
    &:hover {
      color: black;
    }
  }
}

