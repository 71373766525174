@import "../static/frontend/lib/bower_components/bootstrap/less/mixins.less";
@import "../static/frontend/lib/bower_components/bootstrap/less/alerts.less";
@import "_mixins.less";

hr {
  border: 0;
  border-top: 1px dashed @grayLighter;
  margin: 0.5em 0 0.5em 0;
}

.toolbar {
  //  background: url("../img/noisy-2.png") @grayLighter;
  //  background: @grayLighter;
  //  background: @mutedColor;
  background: url("../img/noisy-2.png") @mutedColor;

  padding: 0.5em;
  margin-bottom: 1em;
  display: inline-block;
  height: 45px;

  &.muted {
    background: none;
  }

  &.wide {
    display: block;
  }
  &.clear {
    background: none;
  }
  &.centered {
    text-align: center;
  }

  .report-button {
    margin-left: 5px !important;
  }
}

.vertical-content {
  margin: 0.5em 0;
}

.object-icon {

}

.page {
  //  border-radius: 3px;
  //  border: 1px solid @grayLighter;
}

//
// Page elements
//

.page-header {
  position: relative;
  top: -15px;
  padding: 10px 0;
  margin: 0 0 1em 0;
}

// report page is only available for agriplace workflow
.assessment-report-app {
  .header {
    color: @grayLight;
    .assessment-name {
      color: @color1Dark;
    }
  }

  .table-heading {
    color: @grayLight;
  }

  .table-outline {
    border: solid 2px @grayLighter;
  }

  .evaluation-results-table {
    .positive-compliance-percentage {
      color: @color1Dark;
    }
    .negative-compliance-percentage {
      color: @red;
    }
  }
}

.breadcrumb {
  margin-bottom: 5px;
  padding: 0;
  background: none;
}

.page-title {
  span[class*="icon-"] {
    font-size: 0.7em;
  }
  .small {
    color: @mutedColor;
  }
}

.page-subtitle {
  color: @color3
}

//.page-secondary-title {
//  background: #f0f5f7;
//  padding: 0.5em;
//  margin-top: 0;
//  border-top: 3px solid #e0e5e7;
//}

// Tabs

.nav-tabs {
  font-weight: bold;
  margin-bottom: 1em;
}

.nav-tabs .active, .nav-tabs .active a:hover {
  //  border-top:  3px solid @color2 !important;
}

// Item box list

ul.item-card-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item-card {
  display: inline-block;
  width: 200px;
  height: 120px;
  margin: 0 0.5em 0.5em 0;
  padding: 1em;
  background: url("../img/noisy-2.png") @color1;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  overflow: hidden;
  .transition(background-color 0.5s);
  //  .shadow;
  border-width: 0 1px 4px 1px;

  &:hover {
    background: url("../img/noisy-2.png") darken(@color2, 10%);
    border-color: darken(@color2, 10%);
    box-shadow: 0;
    text-decoration: none;
    color: #fff;
  }

  .title {
  }

  .icon {
    font-size: 2em;
    margin-left: 0.5em;
  }
}

a.item-card-create {
  background: white;
  border: 1px solid @mutedColor;
  text-align: center;
  //  min-height: 0;
  font-weight: bold;
  color: @link-color;

  span[class*="icon"] {
    color: @link-color;
  }

  &:hover {
    span[class*="icon"] {
      color: white;
    }
    border-color: white;
  }
}

.item-list-toolbar {
  padding: 0.5em 0;
  text-align: right;
}

/*
 *
 * Tiles
 *
 */
.tile {
  padding: 0.5em;
  /*	background: #F0F5F7;*/
  display: inline-block;
  width: 155px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
  margin-right: 5px;
  text-decoration: none;

  &:hover {
    background: @mutedColor;
    text-decoration: none;
  }

  &:active {
    background: @color2Light;
    color: white;
    text-decoration: none;
  }

}


.tile-header {
  font-size: 3em;
  font-family: @font-family-headings;
  font-weight: 300;
  padding-bottom: 0.3em;
}

/// Hero slice ///

.hero-slice {
  background: url("../img/noisy-2.png") darken(@color1, 3%);
  color: white;
  padding: 2em;
  font-family: @font-family-headings;
  //  font-family: "Carrois Gothic";
  font-weight: 300;
  border-top: 3px dashed @color1;
}

.hero {
  text-align: center;
  font-size: 2em;
  line-height: 1.5em;
  font-style: italic;
}

.hero .big {
  font-size: 1.5em;
  font-style: normal;
}

//.slice-wrapper {
//  display: inline-block;
//  width: 100%;
//}

.slice {
  min-width: 1080px;
  width: 100%;
  padding: 1em 0;
}

.slice.light {
  background: white;
}

.slice.muted {
  background: @mutedColor;
}

.slice.dark {
  background: @darkColor;
  color: white;
}

.slice.dark > a {
  color: white;
}

.site-slice {
  padding: 2em;
}

.site-slice h2 {
  color: @text-color;
  font-family: @font-family-base;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.25em 0;
}

.dashed-heading hr {
  border-top: 2px dashed @grayLight;
  position: relative;
  top: 2em;
}

.dashed-heading h1 {
  display: inline-block;
  background: @mutedColor;
}

/* Box */
.box {
  background: #ffffff;
  border-radius: @border-radius-small;
  border: 1px solid @color1;
  border-bottom-width: 3px;
  word-break: break-strict;
  margin-bottom: 10px;

  .toolbar {
    margin-bottom: 0;
    background: @mutedColorLight;
  }

  .box-header {
    padding: 0 1em;
    background: url("../img/noisy-2.png") @color1;

    h3 {
      color: #fff;
    }
  }

  .box-header-toolbar {
    float: right;
  }

  .box-footer {
    background: #f8f8f80;
    padding: 1em;
    border-top: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
  }

  // variations

  &.box-last {
    border-radius: 0 0 5px 5px;
  }

  &.box-muted {
    .box(@mutedColor);
    .box-header {
      background: none;
    }
  }
}

.box-header h3, .page-section-header h3 {
  display: inline-block;
}

//
// Charts
//
.chart {
  height: 200px;
  width: 500px;
  margin: 2em auto;
}

.list-result {
  padding: 1em;
  border-bottom: 1px solid @mutedColor;
  margin-bottom: 1em;

  .list-result-title {
    font-size: 1.1em;
    font-weight: bold;
  }

}

// Independent content padded from its container
.padded-content {
  padding: @font-size-base;
}

.padded-content-except-top {
  padding: 0 @font-size-base @font-size-base @font-size-base;
}

//
// Boxes
//
.box (@box-color: @grayLighter) {
  background: white;
  margin-bottom: 1em;
  border: 1px solid @box-color;
  //  border-bottom-width: 3px;
  border-radius: @border-radius-small;

  .box-header {
    background: url("../img/noisy-2.png") @box-color;
    padding: 0.75*@font-size-base @font-size-base;

    h2 {
      color: darken(@box-color, 40%);
      margin: 0;
      text-transform: uppercase;
      font-size: @font-size-base;
      font-weight: bold;
      letter-spacing: normal;
    }
  }
  .box-header-toolbar {
//    height: 1px;
    float: right;
  }

  .box-content {
    padding: @font-size-base;
  }
}

.page-section-boxed {
  .box(@mutedColorDark);

  .page-section-header {
    .box-header;
  }

  .page-section-header-toolbar {
    .box-header-toolbar;
  }

  .page-section-content {
    .box-content;
  }
}

.page-section {
  //  .box(@mutedColorDark);

  .page-section-header {
    //    .box-header;
  }

  .page-section-content {
    //    .box-content;
  }

  .toolbar {
    margin-bottom: 0;
  }
}

.page-section-highlighted {
  .box(@color1);
}

.page-section-green {
  .page-section-header {
    background: #ffffff;
    h1 {
      font-size: 28px;
      small {
        font-size: 22px;
        a {
          i {
            font-size: 18px;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .page-section-content {
    background: rgba(237, 252, 228, 0.70);
    padding: 10px 21px;
    .item-card-list {
      .item-card {
        width: 200px;
      }
      a.item-card {
        margin: 0 0.5em 0 0;
        h3 {
          font-size: 44px;
          margin-top: 8px;
          margin-left: 0;
          color: #ffffff;
        }
      }
    }
  }
  .page-section-footer {
    background: #ffffff;
    padding: 10px;
  }
}

//
// (Organization) Public profile block
//

.public-profile-block {
  padding: 1em;
  margin-bottom: 1em;
}

//
//
//
.organization-business-card {
  padding: 0.5em 0;

  .name {
    font-size: 3em;
    font-family: @font-family-headings;
    font-weight: light;
    color: @color3;
  }

  .description {

  }
  .address {
  }
  .contact {
    font-size: 0.9em;
    color: @grayLight;
  }
  .logo-column {
    width: 90px;
    float: left;

    img {
      max-width: 78px;
      max-height: 78px;
      border-radius: 5px;
    }

    span.logo {
      font-size: 24px;
    }
  }

  .content-column {
    margin-left: 90px;
  }
}

// Assessments
.partner-select {
  width: 80%;
}

.modal.modal-wide {
  width: 900px;
  margin-left: -450px;
}

.current-organization {
  color: @text-color;
  a {
    color: @text-color;
  }
}

.hidden {
  display: none !important;
}

.help-block {
  font-size: 0.8em;
}

ul.list-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

//
// Organization listing
//
.organization-listing {
  .logo-box {
    width: 50px;
    height: 50px;
    text-align: center;
    padding-right: 0.5em;

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  h4 {
    margin: 0;
  }
}

.action-btn {
  width: 100%;
}

.small-logo {
  width: 32px;
  height: 32px;
  text-align: center;

  img {
    max-width: 32px;
    max-height: 32px;
  }
}

.label-cell {
  width: 200px;
  text-align: right;
}

//
// Flippable section
//

.flip-section {
  .edit {
    display: none;
  }
}

.corner-menu {
  float: right;
  height: 1px;
}

.icon-label {
  .icon {
    padding: 0;
    display: inline-block;
    vertical-align: middle;
  }
  .content {
    padding: 0;
    display: inline-block;
    vertical-align: middle;
  }
}

// Option button (like Yes/No/Maybe radio toggle)
.btn-option {
  .button-variant(@btn-default-color; white; @btn-default-border);
  .btn-styles(@btn-default-bg);

  &.active {
    .btn-styles(@color3);
    color: white;
    text-shadow: none;
  }
}

//
// Wizard arrows
// based on FuelUX
//

.nav-wizard {
  position: relative;
  overflow: hidden;
  margin-bottom: 1em;
  background-color: white;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *zoom: 1;
  //  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  //  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  //  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}

.nav-wizard:before,
.nav-wizard:after {
  display: table;
  line-height: 0;
  content: "";
}

.nav-wizard:after {
  clear: both;
}

.nav-wizard ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}

.nav-wizard ul.previous-disabled li.complete {
  // cursor: default;

  &:hover {
    color: #468847;
    cursor: default;
    background: #f3f4f5;

    .chevron:before {
      border-left-color: #f3f4f5;
    }
  }
}

.nav-wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-weight: bold;
  line-height: 46px;
  color: #999999;
  cursor: default;
  font-size: 14px;
  //  background: #ededed;

  .chevron {
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 1;
    display: block;
    border: 24px solid transparent;
    border-right: 0;
    border-left: 14px solid #d4d4d4;
  }

  .chevron:before {
    position: absolute;
    top: -24px;
    right: 1px;
    display: block;
    border: 24px solid transparent;
    border-right: 0;
    //    border-left: 14px solid #ededed;
    border-left: 14px solid white;
    content: "";
  }

  a {
    display: block;
    text-decoration: none;
  }
}

.nav-wizard ul li:hover{
  background-color: #f6f6f6;
  cursor: pointer;

  .chevron:before{
    border-left: 14px solid #f6f6f6;
  }
}



//.nav-wizard ul li.complete {
//  border-bottom: 3px solid @color1;
//
////  .chevron:before {
////    border-left: 14px solid #f3f4f5;
////  }
//
//  &:hover {
////    cursor: pointer;
//    border-bottom-color: @color1Dark;
//
//    .chevron:before {
//      border-left: 14px solid #e7eff8;
//    }
//  }
//}

.nav-wizard ul li.active {
  color: #3a87ad;
  //  background: #f1f6fc;
  background: @color3;

  .chevron:before {
    //  border-left: 14px solid #f1f6fc;
    border-left: 14px solid @color3;
  }

  a {
    color: white;
  }
}

.nav-wizard ul li .badge {
  margin-right: 8px;
}

.nav-wizard ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}

.faded {
  color: #aaa;
}

td.tight {
  width: 1px;
}

.form-actions {
  text-align: center;
}

.table td {
  vertical-align: middle;
}

.thumbnail-link {

}

.thumbnail-link:hover {
  border-color: @color1;
}

.thumbnail-link:hover a {
  text-decoration: none;
}

.thumbnail-small {
  width: 100px;
  //    height: 100px;
}

.link {
  cursor: pointer;
}

.row-list-item {
  margin-bottom: 1em;
  border: 1px solid @mutedColor;

  transition: border-color 0.5s ease-in-out,
  background-color 0.5s ease-in-out;

  &:hover {
    border-color: @color1;
  }
}

//
// Small item card
//

.small-item-card {
  width: 133px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
  //    background: @color3;
  //    color: white;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: @border-radius-small;
//  border: 1px solid @color3;
  text-align: center;
  color: @color3Dark;

  a {
    display: inline-block;
    color: @color1;
    text-decoration: none;
    font-weight: bold;

    .icon {
      color: @color1;
    }

    &:hover {
      color: @color1Dark;

      .icon {
        color: @color1Dark;
      }
    }
  }

  .icon {
    font-size: 4em;
    color: @color3Light;
  }
}

.small-item-card-photo {
  img {
    width: 100%;
  }
}

.small-item-card-list {
  padding: 0;
}

.shady {
  background: @mutedColor;
}

.item-bordered {
  border: 1px solid @mutedColor;
}

.alert-error {
  .alert-danger;
}

.busy-feedback {
  text-align: center;
  color: @color3;
  padding: 2em;
}

.busy-feedback-small {
  text-align: center;
  color: @color3;
  padding: 1em;
}

.busy-feedback-tomato {
  text-align: center;
  padding: 4em;
  height: 64px;
  background-image: url('../img/spinner-tomato-1.gif');
  background-size: 64px 64px;
  background-repeat: no-repeat;
  background-position: center;
}

.form-single-choice-buttons-editor {
  padding: 1em 0;
}

.form-radio-buttons{
}

.print-button{
  margin-left : 8px;
}

input.datepicker{
  background: url("../img/calendar.png");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: right 0px;
  padding-right: 36px;
}

input.datetimepicker{
  background: url("../img/calendar.png");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: right 0px;
  padding-right: 36px;
}

.historical-attachment-link {
  color: #558d32;
  &:hover {
    color: #33551e;
  }
}

.historical-attachment-box {
  .historical-title {
    color: #558d32;
  }
}

.associated-questions {

  .popup-overlay-button {
    color: #558d32;
    &:hover {
      color: #33551e;
    }
  }

  .overlay-popup {
    border: 1px solid @mutedColor;
    transition: border-color 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
    &:hover {
      border-color: @color1;
    }
  }
}

.organization-details-popup {
  &.right > .arrow {
    border-right-color: @mutedColor;
  }
  border: 1px solid @mutedColor;

  .header {
    .name {
      color: @color1;
    }
    .close-button {
      color: @mutedColorDark;
    }
  }
}

.CreateCertificateLayout {
  table {
    .divider {
      border-top: solid 1px @mutedColor;
    }
  }
}

.questionnaire-filters {
  border-bottom: solid 1px @mutedColor;
  background: @body-bg;
  height: 45px;
}

.page-print-title {
  color: @text-color;
  .tab-name {
    color: @mutedColorDark;
  }
}

.crops-warning-symbol {
  color: #FDA91A;
}

.crops-warning-tooltip {
  font-size: 12px;
}

.crops-warning-disclaimer {
  font-size: 13px;
}

#nprogress {
  .bar {
    background: @color1Light !important;
  }

  .peg {
    box-shadow: 0 0 10px @color1Light, 0 0 5px @color1Light !important;
  }
}
